.ant-layout-header {
  margin:0 ;
  background: #ffffff;

}
.ant-layout-header2 {
  margin:0 ;
  background: #ffffff;
  

}

.card1 {
  width:  1200px;
  height: auto;
  margin: 0 auto; /* 将元素水平居中对齐 */
  display: flex;
  flex-direction: column; /* 将子元素垂直排列 */
  justify-content: center; /* 在容器中垂直居中子元素 */


  }
  
  .card2 {
    text-transform: uppercase;
    width:  1200px;
    height: auto;
    margin: 0 auto; /* 将元素水平居中对齐 */
    display: flex;
    flex-direction: column; /* 将子元素垂直排列 */
    justify-content: center; /* 在容器中垂直居中子元素 */
    background: #ffffff;
    }
    .card3 {
      background: #007ab5;
      width:  100%;
      height: auto;
      margin: 0 auto; /* 将元素水平居中对齐 */
      display: flex;
      flex-direction: column; /* 将子元素垂直排列 */
      justify-content: center; /* 在容器中垂直居中子元素 */
    
      }
.hleft{
  float: left;
  margin:0;
}
.hright{
  float: right;
  margin:0;
}
.hright2{
  float: right;
  margin:0;
  width: 380px;
}
.center{
  float: center;
  margin:0;
}
.fix-header {
  position: fixed;
  top: 0;
  right: 0;
}
