.ncenter{
  text-align: center;
  background-color: #f0f2f5;

 

}
.hlight {
  margin:0 ;
  height: '200px'

}