@import '~antd/dist/antd.css';
.site-card-border-less-wrapper {
    padding: 30px;
  }
.card {
  height: 100%;
  width: 100%;


  }
  .middle {
    width:  100%;
    height: auto;
    margin: 0 auto; /* 将元素水平居中对齐 */
    display: flex;
    flex-direction: column; /* 将子元素垂直排列 */
    justify-content: center; /* 在容器中垂直居中子元素 */
  background-color: #ffffff;
  
    }
    .middle2 {
      width:  1200px;
      height: auto;
      margin: 0 auto; /* 将元素水平居中对齐 */
      display: flex;
      flex-direction: column; /* 将子元素垂直排列 */
      justify-content: center; /* 在容器中垂直居中子元素 */
    background-color: #ffffff;
    
      }
